<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :name="name">
    <v-checkbox
      v-model="currentValue"
      :name="name"
      :class="getClass(errors)"
      :label="label"
    >
    </v-checkbox>
    <span class="input__error-message">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    custom_class: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },

  data: function () {
    return {
      currentValue: this.checked,
    };
  },

  watch: {
    currentValue(val) {
      // allows us to use v-model on our input.
      this.$emit("input", val);
    },
  },

  methods: {
    getClass(errors) {
      let futurClasses = "";
      if (errors[0]) {
        futurClasses += " is-invalid ";
      }

      futurClasses += this.custom_class;
      return futurClasses;
    },
  },
};
</script>

<style>
.v-input--selection-controls .v-input__slot > .v-label {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 200;
}
</style>
