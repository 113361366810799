<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col v-if="affiliation" cols="12">
          <div>
            <span class="font-weight-bold">IBAN : </span>
            {{ affiliation.IBAN }}
          </div>

          <div>
            <span class="font-weight-bold">BIC : </span>
            {{ affiliation.BIC }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},

  data: () => ({}),

  computed: {
    affiliation() {
      if (!this.item.MKP_Rib) return false;
      return JSON.parse(this.item.MKP_Rib);
    },
  },

  props: {
    item: {
      type: Object,
    },
  },
};
</script>
