<template>
  <div class="p-4">
    <ValidateForm @validated="submit" :loading="loading" style="width: 100%">
      <v-row>
        <v-col cols="12">
          <h5>Caractéristiques:</h5>

          <DatePicker
            class="w-150"
            v-model="data.LicenceDateDebut"
            label="Date de début de validité:"
            :minToday="true"
            rules="required"
            :key="refreshDates + 1"
          />
          <InputSelect
            class="w-150"
            v-model="data.LicenceNbJour"
            type="number"
            :items="nbDays"
            label="Nombre de jours"
            placeholder="Sélectionnez un nombre de jours"
            rules="required"
          ></InputSelect>
        </v-col>
        <v-col>
          <h5>Informations sur le porteur:</h5>

          <v-row>
            <v-col cols="12">
              <inputText
                label="Nom:"
                rules="required|min:2|max:250|regexname|dashCounter"
                v-model="data.CT_Nom"
              />
              <inputText
                label="Prénom:"
                rules="required|min:2|max:250|regexname"
                v-model="data.CT_Prenom"
              />

              <DatePicker
                v-model="data.CT_DateNaissance"
                label="Date de naissance:"
                rules="required"
                :maxDate="$moment().subtract(1, 'days').format('YYYY-MM-DD')"
                :key="refreshDates + 2"
                class="w-150"
              />

              <inputText
                v-model="data.CT_Email"
                rules="email"
                label="Adresse courriel"
              ></inputText>

              <AddressAutocomplete
                :item="data.AD_Adresse1"
                rules="min:6|max:50"
                label="Adresse"
                :minimum_characters="3"
                ref="adresseAuto"
                @onSelected="adresseSelected"
                :restrictCountry="restrictCountry"
              ></AddressAutocomplete>

              <inputText
                v-model="data.AD_Adresse2"
                rules="min:0|max:50"
                label="Complément d'adresse"
              />

              <inputText
                v-model="data.AD_CodePostal"
                :key="refreshCodePostal"
                rules="digits:5"
                label="Code postal"
                class="w-150"
              />

              <inputText
                v-model="data.AD_Ville"
                :key="refreshVille"
                rules="min:1|max:60"
                label="Ville"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-card-actions class>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="!hasCodeFederal"
          rounded
          color="blue-dark text-white font-weight-bold"
          >Enregistrer</v-btn
        >
      </v-card-actions>
    </ValidateForm>
  </div>
</template>

<script>
import inputText from "@/components/utils/input.vue";
import DatePicker from "@/components/utils/datePicker.vue";
import ValidateForm from "@/components/utils/form.vue";
import AddressAutocomplete from "@/components/utils/adresseAutocomplete";
import InputSelect from "@/components/utils/select.vue";
import { sleep, send_email } from "@/plugins/utils";
import { mapActions, mapGetters } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import {formatFluxFromLicenceDecouverte, insertFlux} from "@/plugins/fluxService";

export default {
  components: {
    DatePicker,
    inputText,
    AddressAutocomplete,
    InputSelect,
    ValidateForm,
  },

  data: () => ({
    data: {},
    restrictCountry: "FR",
    dateFinIsDisabled: true,
    refreshCodePostal: 0,
    refreshVille: 99,
    nbDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    loading: false,
    MKP_Tarif: null,
    refreshDates: 0,
    licence: {},
    licencesDecouverte: [],
    findMaxID: 0
  }),

  async created() {
     this.licencesDecouverte = await this.getStructureLicenceDecouverte({
      ID_Saison: this.currentSaisonId,
      ID_Structure: this.currentStructureId,
    });
    const lastlicencedecouverte = await this.getLastLicenceDecouverte();
    this.findMaxID = lastlicencedecouverte[0].id;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaisonId"]),
    ...mapGetters("structure", ["currentStructureId", "hasCodeFederal"]),
  },

  methods: {
    ...mapActions("utilisateurs", ["insertLicenceDecouverte"]),
    ...mapActions("structure", ["getTarifLicenceDecouverte", "getStructureLicenceDecouverte", "getLastLicenceDecouverte"]),

    async adresseSelected(item) {
      if (typeof item === "string") return (this.data.Adresse1 = item);
      await sleep(10);
      let adresse1 = item[0].long_name + " " + item[1].long_name;
      this.$refs.adresseAuto.currentValue = adresse1.toUpperCase();
      this.data.AD_Adresse1 = adresse1.toUpperCase();
      this.data.AD_Adresse2 = "";
      this.data.AD_Ville = item[2].long_name.toUpperCase();
      this.data.AD_CodePostal = item[6] ? item[6].long_name.toUpperCase() : "";
      // console.log( this.userAdresse)
      this.refreshCodePostal++;
      this.refreshVille++;
    },
    async submit() {
      this.loading = true;
      const MKP_Tarif = (
        await this.getTarifLicenceDecouverte({
          ID_Saison: this.currentSaisonId,
          NomTarif: "Licence découverte",
        })
      )[0];
      if (!MKP_Tarif)
        return error_notification(
          "Aucun tarif pour la saison associée: " + this.currentSaisonId
        );
      try {
        this.data.ID_Saison = this.currentSaisonId;
        this.data.ID_Structure = this.currentStructureId;
        this.data.CT_Nom = this.data.CT_Nom.toUpperCase();
        this.data.CT_Prenom =
          this.data.CT_Prenom.charAt(0).toUpperCase() +
          this.data.CT_Prenom.slice(1);

        //   création du flux financier de la licence découverte
        let fluxStructure = {
          ID_FluxFinancierDebit: 0,
          ID_Saison: this.currentSaisonId,
          ID_Structure: this.currentStructureId,
          ID_Traitement: 0,
          ID_Utilisateur: null,
          MontantHT: MKP_Tarif.MontantUnitaire * this.data.LicenceNbJour,
          MontantTTC: MKP_Tarif.MontantUnitaire * this.data.LicenceNbJour,
          MontantTVA: 1,
          NomFluxFinancier: `Licence découverte ${this.data.CT_Nom} ${this.data.CT_Prenom} - N°${this.findMaxID + 1 }`,
          EST_Reglement: false,
          MKP_FluxFinancierDetail: {
            data: [
              {
                ID_Structure: this.currentStructureId,
                ID_Utilisateur: null,
                ID_Saison: this.currentSaisonId,
                ID_Tarif: MKP_Tarif.id,
                MontantHT: MKP_Tarif.MontantUnitaire * this.data.LicenceNbJour,
                MontantTTC: MKP_Tarif.MontantUnitaire * this.data.LicenceNbJour,
                MontantTVA: 1,
                FIN_CodeAnalytique: 0,
                FIN_CodeCompta: 0,
                NomFluxFinancierDetail: `Licence découverte ${this.data.CT_Nom} ${this.data.CT_Prenom} - N°${this.findMaxID + 1 }`,
                PrixUnitaire: MKP_Tarif.MontantUnitaire,
                Quantite: this.data.LicenceNbJour,
              },
            ],
          },
        };

        this.data.MKP_FluxFinanciers = { data: fluxStructure };
        this.licence = await this.insertLicenceDecouverte({
          licence: this.data,
        });
        // NEW FLUX BACK FFME
        await insertFlux(formatFluxFromLicenceDecouverte(this.licence.data.insert_STR_LicenceDecouverte.returning[0].id, this.currentSaisonId, this.currentStructureId, 'achat'));
        if (this.data.CT_Email) {
          await this.sendEmail();
        }
        this.data = {};
        this.data.LicenceDateDebut = "";
        this.data.CT_DateNaissance = "";
        this.loading = false;
        this.refreshDates++;
        // sendEMail

        success_notification("La licence découverte a bien été enregistrée");
      } catch (error) {
        this.loading = false;
        error_notification("Une erreur est survenue...");
      }
    },

    async sendEmail() {
      const numberID =
        this.licence.data.insert_STR_LicenceDecouverte.returning[0].id;
      let payloadEmail = {
        Email: this.data.CT_Email,
        LicenceDecouverteID: numberID.toString(),
        NbDays: this.data.LicenceNbJour.toString(),
        StartDate: this.$moment(this.data.LicenceDateDebut).format(
          "DD/MM/YYYY"
        ),
        ID_Structure: this.currentStructureId.toString(),
        Context: "createLicenceDecouverte",
        SlugModule: "gestion_des_licences__saisir_une_licence_decouverte",
      };
      try {
        const response = await send_email(payloadEmail);
        if (!response.ok) {
          error_notification("Une erreur est survenue lors de l'envoi du mail");
        }
      } catch (e) {
        console.log("error on email: " + e);
      }
    },
  },
};
</script>

<style>
.w-150 .v-input,
.w-150 input {
  max-width: 250px !important;
}
</style>
