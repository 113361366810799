<template>
  <div>
    <div>
      <div class="py-6 px-4 text-center">
        <div>
          Vous souhaitez créer un licence au nom de
          <span class="font-weight-bold"
            >{{ searchData.CT_Nom }} {{ searchData.CT_Prenom }}.</span
          >
        </div>
        <div>
          Vous devez comparer ce nom avec les utilisateurs déjà présents dans la
          base de données pour éviter les doublons.
        </div>
        <div>
          La liste ci dessous présente les homonymes proche de
          <span class="font-weight-bold"
            >{{ searchData.CT_Nom }} {{ searchData.CT_Prenom }}.</span
          >
          <!-- <a href="#" @click.prevent="modalEnSavoirPlusIsOpen = true">En savoir plus</a> -->
        </div>
        <div class="mt-4">
          <v-btn @click.prevent="openCreateUser" class="btn btn-primary"
            >Créer un nouvel utilisateur</v-btn
          >
        </div>
      </div>
    </div>
    <userList
      :context="context"
      :headers="headers"
      :actions="actions"
      :items="users"
      isModule="saisirUneLicence"
      hasItems
      ref="userList"
    ></userList>

    <modalEnSavoirPlus
      v-if="modalEnSavoirPlusIsOpen"
      :isOpen="modalEnSavoirPlusIsOpen"
      @onClose="modalEnSavoirPlusIsOpen = false"
    >
      <template v-slot:title>En savoir plus</template>
      <template v-slot:body>Text a recopier ici</template>
    </modalEnSavoirPlus>

    <modalCreateUser
      v-if="modalCreateUserIsOpen"
      :isOpen="modalCreateUserIsOpen"
      @onCreated="onCreated"
      :context="context"
      :item="searchData"
      @onClose="modalCreateUserIsOpen = false"
    />
    <modalLicence
      :user="newUser"
      :context="context"
      v-if="modalIsOpenEditLicenceIsOpen"
      :modalIsOpen="modalIsOpenEditLicenceIsOpen"
      @onUpdate="onUpdateNewuserLicence"
      @onClose="modalIsOpenEditLicenceIsOpen = false"
    ></modalLicence>
  </div>
</template>

<script>
import modalEnSavoirPlus from "@/components/utils/modals/simpleText.vue";
import modalCreateUser from "@/components/utils/modals/User/User__CreateUser.vue";
import modalLicence from "@/components/utils/modals/Licence/Licence__Modal.vue";
import userList from "@/components/User/List/User__List.vue";
import { mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import {trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters} from "@/plugins/utils";

export default {
  components: {
    userList,
    modalEnSavoirPlus,
    modalCreateUser,
    modalLicence,
  },

  data: () => ({
    headers: [
      { text: "Nom", value: "CT_Nom" },
      { text: "Prénom", value: "CT_Prenom" },
      { text: "Date de naissance", value: "DN_DateNaissance" },
      { text: "Numéro de licence", value: "LicenceNumero" },
      { text: "Structure", value: "NomStructure" },
      { text: "Licence type", value: "ID_LicenceType" },
      // { text: "Saison", value: "ID_LicenceSaison" },
      { text: "Actions", value: "actions" },
    ],
    actions: "show|editLicence",
    modalEnSavoirPlusIsOpen: false,
    modalCreateUserIsOpen: false,
    newUser: null,
    modalIsOpenEditLicenceIsOpen: false,
  }),

  methods: {
    ...mapActions("utilisateurs", ["updateUser", "updateLastLicenceNumber","getUtilisateur"]),
    openCreateUser() {
      this.modalCreateUserIsOpen = true;
    },

    async onCreated(newUser) {
      await this.storeNewUsser(newUser);
      console.log(newUser);
    },

    async storeNewUsser(user) {
     
      const currentLicenceNumero = await this.getUtilisateur({ID_Utilisateur: user.id})
      let payload = {
        id: user.id,
        CT_Email: user.Email,
        CT_EST_Civilite: user.CT_EST_Civilite,
        ID_PaysNationalite: user.ID_PaysNationalite,
        CT_Prenom: trimAndCapitalizeFirstLetter(user.CT_Prenom),
        CT_Nom: trimAndUppercaseAllLetters(user.CT_Nom),
        DN_DateNaissance: user.DN_DateNaissance,
        DN_CommuneNaissance: user.DN_CommuneNaissance,
        DN_CommuneNaissance_CodeInsee: user.DN_CommuneNaissance_CodeInsee,
        CT_TelephoneMobile: user.CT_TelephoneMobile,
        CT_TelephoneFixe: user.CT_TelephoneFixe,
        EST_Licencie: true,
        STR_StructureUtilisateurs: {
          data: {
            EST_Actif: true,
            EST_RoleFederal: false,
            EST_Salarier: false,
            EST_StructureLicencie: true,
            ID_Structure: this.context.ID_Structure,
          },
        },
      };

      // on ajoute le numéro de licence si il n'est pas null ou undefined
      if (currentLicenceNumero && currentLicenceNumero.LicenceNumero !== null && currentLicenceNumero.LicenceNumero !== undefined) {
        payload.LicenceNumero = currentLicenceNumero.LicenceNumero;
      }

      try {
        let newUser = await this.updateUser({ user: payload });

        success_notification("L’utilisateur a bien été crée");

        this.$emit("onNewUserCreated", user);
        this.$emit("onNextStep");
        this.addLicence(newUser);
      } catch (e) {
        error_notification("Oups, une erreur est survenue");
        console.log(e);
      }

      // console.log(payload);
      return;
    },

    addLicence(newUser) {
      this.modalCreateUserIsOpen = false;
      let user = {};
      // the id of the structureutilisateur table
      user.id = newUser.STR_StructureUtilisateurs[0].id;
      user.ID_Structure = this.context.ID_Structure;
      user.EST_StructureLicencie =
        newUser.STR_StructureUtilisateurs[0].EST_StructureLicencie;
      (user.UTI_Utilisateur = newUser), (user.ID_Utilisateur = newUser.id);
      this.newUser = user;
      this.modalIsOpenEditLicenceIsOpen = true;
    },

    onUpdateNewuserLicence(user) {
      this.$emit("onLicenceUpdated", user);
      // console.log(user);
    },
  },

  props: {
    context: {
      type: Object,
    },
    users: {
      type: Array,
      default: () => [],
    },
    searchData: {
      type: Object,
    },
  },
};
</script>
