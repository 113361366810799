<template>
  <ul
    class="moncompte__navigation-body d-flex flex-column justify-space-between text-center pl-0"
  >
    <li>
      <router-link
        :to="{
          name: 'gestion_des_licences__liste_licences_decouverte',
        }"
        >Liste des licences découverte</router-link
      >
    </li>
    <li>
      <router-link
        :to="{
          name: 'gestion_des_licences__saisir_une_licence_decouverte',
        }"
        v-if="hasCodeFederal"
        >Saisir une licence découverte</router-link
      >
    </li>
    <!-- <li><router-link :to="{name : 'mon_compte__activitees_pratiques'}">Activitées pratiquées</router-link></li> -->
    <!-- <li><router-link :to="{name : 'mon_compte__notification'}">Paramètrage des notifications</router-link></li> -->
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('structure', ['hasCodeFederal'])
  }
};
</script>

<style lang="scss" scoped>
ul {
  margin-bottom: 0;
}
</style>
