<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <span class="font-weight-bold">Nom structure : </span>
          {{ item.STR_NomStructure }}
        </v-col>
        <v-col cols="12" md="4" v-if="item.ID_TypeStructure == 6">
          <span class="font-weight-bold">Acronyme : </span>
          {{ item.NomStructureComplement }}
        </v-col>
        <v-col
          cols="12"
          md="4"
          v-if="
            item.ID_TypeStructure &&
              allStructureType.find((s) => s.id == item.ID_TypeStructure)
          "
        >
          <span class="font-weight-bold">Type de structure : </span>
          {{
            labelTypeStructure
          }}
        </v-col>

        <v-col cols="12" md="4">
          <span class="font-weight-bold">SIRET : </span> {{ item.JUR_Siret }}
        </v-col>
        <v-col cols="12" md="4" v-if="item.ID_TypeStructure == 6">
          <span class="font-weight-bold">Chiffre d'affaire : </span>
          {{ item.ChiffreAffaire }}
        </v-col>
        <v-col cols="12" md="4" v-if="item.ID_TypeStructure != 6">
          <span class="font-weight-bold">Agrément jeunesse et sport : </span>
          {{ item.JUR_AgrementJeunesseSports }}
        </v-col>
        <v-col cols="12" md="4" v-if="item.ID_TypeStructure != 6">
          <span class="font-weight-bold">Equivalent temps plein : </span>
          {{
            affiliation.SAL_EquivalentTempsPlein
              ? affiliation.SAL_EquivalentTempsPlein
              : "-"
          }}
        </v-col>
        <v-col cols="12" md="4" v-if="item.ID_TypeStructure != 6">
          <span class="font-weight-bold"
            >Collecteur formation professionnelle :
          </span>
          {{
            affiliation.ID_CollecteurFormationProfessionnelle
              ? affiliation.ID_CollecteurFormationProfessionnelle
              : affiliation.ID_CollecteurFormationProfessionnelle.NomCollecteur
          }}
        </v-col>

        <v-col cols="12" md="4">
          <span class="font-weight-bold">Presentation : </span>
          {{ item.STR_InfoPresentation }}
        </v-col>
        <v-col cols="12" md="4">
          <span class="font-weight-bold">Sélection de la saison : </span>
          {{ affiliation.ID_Premiere_Saison }}
        </v-col>
        <v-col cols="12">
          <span class="font-weight-bold">Période de validité : </span>
          {{ validityPeriod }}
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="affiliation" cols="12" md="6">
          <div>
            <span class="font-weight-bold"
              >Accueil de personnes en situation de handicap :
            </span>
            {{ affiliation.CLUB_EST_AcceuillantHandicape ? "Oui" : "Non" }}
          </div>

          <div>
            <span class="font-weight-bold">Mineurs acceptés : </span>
            {{ affiliation.CLUB_EST_AcceuillantMineur ? "Oui" : "Non" }}
          </div>

          <div>
            <span class="font-weight-bold">Âge minimum : </span
            >{{ affiliation.CLUB_AcceuillantMineurAge }}
          </div>

          <div>
            <span class="font-weight-bold"
              >Propose les passeports escalade :
            </span>
            {{ affiliation.CLUB_EST_AcceptePassSportCulture ? "Oui" : "Non" }}
          </div>

          <div>
            <span class="font-weight-bold">Prêt de matériel (EPI) : </span>
            {{ affiliation.CLUB_EST_PretMateriel ? "Oui" : "Non" }}
          </div>
        </v-col>

        <v-col cols="12" md="6" v-if="item.ID_TypeStructure != 6">
          <div>
            <span class="font-weight-bold"
              >Convention avec les Caisses d'Allocation Familialles :
            </span>
            {{
              affiliation.CLUB_EST_ConventionAllocationFamilliale
                ? "Oui"
                : "Non"
            }}
          </div>

          <div>
            <span class="font-weight-bold"
              >Accepte les chèques vacances :
            </span>
            {{ affiliation.CLUB_EST_AccepteChequesVacances ? "Oui" : "Non" }}
          </div>

          <div>
            <span class="font-weight-bold">Accepte les coupons sport : </span>
            {{ affiliation.CLUB_EST_AccepteCouponSport ? "Oui" : "Non" }}
          </div>

          <div>
            <span class="font-weight-bold"
              >Accepte les pass'sport culture:
            </span>
            {{ affiliation.CLUB_EST_AcceptePassSportCulture ? "Oui" : "Non" }}
          </div>
        </v-col>

        <v-col cols="12" v-if="item.ID_TypeStructure != 6">
          <h6>Activités pratiqués</h6>
          <div>
            <span v-for="(activity, index) in activites" :key="index"
              >{{ activity.LIST_ActiviteType.NomActivite }},
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},

  data: () => ({
    allStructureType: [],
    validityPeriod: "",
  }),

  computed: {
    affiliation() {
      if (!this.item.STR_Affiliation) return false;
      console.log(
        "STR_Affiliation ::::",
        JSON.parse(this.item.STR_Affiliation)
      );
      return JSON.parse(this.item.STR_Affiliation);
    },

    activites() {
      if (!this.item.ACTI_Activites) return false;
      return JSON.parse(this.item.ACTI_Activites);
    },

    labelTypeStructure() {
      if (this.item.ID_TypeStructure === 3) {
        return 'Établissement Affilié';
      } else if (this.item.ID_TypeStructure === 6) {
        return 'Membre Associé';
      } else if (this.item.ID_TypeStructure === 1 && this.item.EST_SectionAssociation) {
        return "Association (Section de club)";
      } else {
        return 'Association';
      }
    },
  },

  async created() {
    await this.setData();
    console.log(this.activites);
    console.log("this.item !!!!!!", this.item);
    return;
  },

  methods: {
    ...mapActions("basics", ["getListStructureType"]),

    async setData() {
      this.allStructureType = await this.getListStructureType();
      this.validityPeriod = this.item.ID_Premiere_Saison
        ? "01/09/" +
          (this.item.ID_Premiere_Saison - 1) +
          " - " +
          "31/08/" +
          this.item.ID_Premiere_Saison
        : "01/09/" +
          (this.affiliation.ID_Premiere_Saison - 1) +
          " - " +
          "31/08/" +
          this.affiliation.ID_Premiere_Saison;
    },
  },

  props: {
    item: {
      type: Object,
    },
  },
};
</script>
