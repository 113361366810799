<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="licencesDecouverte"
    class="elevation-1 list__table"
    :key="refresh"
  >
    <!-- <template v-slot:item.CT_DateNaissance="{ item }">
      <span>{{$moment(item.CT_DateNaissance).format('DD/MM/YYYY')}}</span>
    </template> -->
    <template v-slot:item.LicenceDateDebut="{ item }">
      <span>{{ $moment(item.LicenceDateDebut).format("DD/MM/YYYY") }}</span>
    </template>
    <template v-slot:item.actions="{ item }" class="d-flex">
      <div class="d-flex">
        <ShowLicenceDecouverte :licence="item"></ShowLicenceDecouverte>
        <div
          v-if="
            hasCodeFederal && $moment(item.LicenceDateDebut).isAfter($moment())
          "
          class="d-flex"
        >
          <UpdateLicenceDecouverte
            @onUpdated="refreshTable"
            :licence="item"
          ></UpdateLicenceDecouverte>
          <RemoveLicenceDecouverte
            :licence="item"
            @removed="refreshTable"
          ></RemoveLicenceDecouverte>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ShowLicenceDecouverte from "@/components/Structure/LicenceDecouverte/LicenceDecouverte__ListActionShowLicence.vue";
import UpdateLicenceDecouverte from "@/components/Structure/LicenceDecouverte/LicenceDecouverte__ListActionUpdateLicence.vue";
import RemoveLicenceDecouverte from "@/components/Structure/LicenceDecouverte/LicenceDecouverte__ListActionRemoveLicence.vue";
export default {
  components: {
    ShowLicenceDecouverte,
    UpdateLicenceDecouverte,
    RemoveLicenceDecouverte,
  },
  async created() {
    this.licencesDecouverte = await this.getStructureLicenceDecouverte({
      ID_Saison: this.currentSaisonId,
      ID_Structure: this.currentStructureId,
    });
  },

  data: () => ({
    headers: [
      { text: "N°", value: "id" },
      { text: "Nom", value: "CT_Nom" },
      { text: "Prénom", value: "CT_Prenom" },
      // { text: "Date de naissance", value: "CT_DateNaissance" },
      { text: "Début de validité", value: "LicenceDateDebut" },
      { text: "Nombre de jours", value: "LicenceNbJour" },
      { text: "Courriel", value: "CT_Email" },
      { text: "Actions", value: "actions" },
    ],
    loading: false,
    refresh: 0,
    licencesDecouverte: [],
  }),
  computed: {
    ...mapGetters("saisons", ["currentSaisonId"]),
    ...mapGetters("structure", ["currentStructureId", "hasCodeFederal"]),
  },
  methods: {
    ...mapActions("structure", ["getStructureLicenceDecouverte"]),
    async refreshTable() {
      this.licencesDecouverte = await this.getStructureLicenceDecouverte({
        ID_Saison: this.currentSaisonId,
        ID_Structure: this.currentStructureId,
      });
    },
  },
};
</script>
