<template>
  <v-row no-gutters class="moncompte__layout">
    <v-col
      cols="12"
      color="blue"
      sm="4"
      lg="3"
      style="height: 100%; overflow-x: hidden;"
    >
      <v-row class="moncompte__navigation-sidebar block" no-gutters>
        <v-col>
          <div class="px-4 mt-6 text-center">
            <h5>Gestion des licences découverte</h5>
          </div>
        </v-col>
        <v-col cols="12" class="moncompte__navigation-sidebar-nav mt-10">
          <Navigation></Navigation>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" sm="8" lg="9" class="moncompte__content">
      <div class="moncompte__title-section">{{ title_section }}</div>

      <div
        class="moncompte__content-content-view align-self-stretch flex-grow-1 px-0 pt-0"
      >
        <transition name="fade" mode="out-in">
          <router-view @setTitle="setTitle"></router-view>
        </transition>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Navigation from "@/components/Navigation/GestionDesLicencesDecouverte__Navigation.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: { Navigation },

  data: () => ({
    title_section: "Licences découverte",
    updateAvatarModalIsOpen: false,
  }),

  created() {
    this.setAppTitle({ title: "Mon compte" });
  },
  computed: {
    ...mapGetters("user", ["currentUserId", "currentUser"]),
    ...mapGetters("saisons", ["selectedSaison"]),
  },

  methods: {
    ...mapActions("app", ["setAppTitle"]),

    setTitle(title) {
      this.title_section = title;
    },
  },
};
</script>

<style lang="scss"></style>
