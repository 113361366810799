<template>
  <div>
    <div class=" p-2 d-flex">
      <selectInput
        :items="Saisons"
        v-model="saisonSelected"
        item_text="ID_saison"
        item_value="ID_saison"
        label="Saison"
        style="margin-left:auto;"
      ></selectInput>
    </div>
    <userList
      :context="{ ID_Saison: currentSaison.id, hasCodeFederal }"
      :headers="headers"
      :actions="actions"
      :items="items"
      :hasItems="true"
      :isLHC="true"
      @hasToReload="reloadQuery"
      :show_select="show_select"
      :isLoading="loading"
      ref="userList"
      :key="`listLHC-${userListKey}`"
    ></userList>
  </div>
</template>

<script>
import userList from '@/components/User/List/User__List.vue';
import { mapGetters } from 'vuex';
import selectInput from '@/components/utils/select.vue';
import { PRODUCT_LICENCE_HORS_CLUB_JEUNE, PRODUCT_LICENCE_HORS_CLUB_ADULTE } from "@/plugins/productService";
import { getLicences } from "@/plugins/licenceService";

export default {
  components: { userList, selectInput },
  name: 'GestionDesLicences__RenouvelerLicencesEnSerie',

  data: () => ({
    mounted: false,
    headers: [
      { text: 'Nom', value: 'user.ctNom' },
      { text: 'Prénom', value: 'user.ctPrenom' },
      {
        text: 'Type de licence',
        value: 'product.label',
      },
      { text: 'Actions', value: 'actions' },
    ],
    actions: 'show',
    items: [],
    userListKey: 0,
    show_select: false,
    saisonSelected: null,
    loading: false,
    selected: false,
  }),

  async created() {
    this.$emit('setTitle', 'Licences hors club');
    this.saisonSelected = this.currentSaison.id;
    await this.setData();
    return;
  },

  computed: {
    ...mapGetters('saisons', ['currentSaison']),
    ...mapGetters('structure', ['hasCodeFederal']),

    Saisons() {
      const saison = this.currentSaison.id;
      let saisons = [];
      for (let year = saison; year >= 2004; year--) {
        saisons.push({ ID_saison: year });
      }

      return [...saisons];
    },
  },

  methods: {
    async setData() {
      this.loading = true;

      const saisonSelected = this.selected ? this.saisonSelected : this.currentSaison.id;

      let licences = await getLicences({
        'and[or][product.slug][1]': PRODUCT_LICENCE_HORS_CLUB_JEUNE,
        'and[or][product.slug][2]': PRODUCT_LICENCE_HORS_CLUB_ADULTE,
        'and[season]': saisonSelected,
        'page': 1,
        'itemsPerPage': 5000,
        'partial': true,
      })

      licences = licences.map((licence) => (
        {
          ...licence,
          UTI_Utilisateur: {
            CT_Nom: licence.user.ctNom,
            CT_Prenom: licence.user.ctPrenom
          },
          ID_Utilisateur: licence.user.id,
        }
      ));

      this.items = licences;
      this.userListKey++;
      this.loading = false;

      return;
    },

    reloadQuery() {},
  },
  watch: {
    async saisonSelected() {
      this.selected = true;

      if (!this.loading) {
        await this.setData();
      }
    },
  },
};
</script>
