<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    @click:outside="closeModal()"
    max-width="600px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        <slot name="title" />
      </v-tab>
    </v-tabs>
    <div class="modal__content">
      <v-tabs-items>
        <v-card flat>
          <div class="text-center py-10">
            <slot name="body" />
          </div>
          <div class="text-center pb-10">
            <v-btn @click="closeModal()">
              Fermer
            </v-btn>
          </div>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({}),

  async created() {},

  methods: {
    closeModal() {
      this.$emit("onClose");
    },
  },

  computed: {},

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "You must provide a title",
    },
    body: {
      type: String,
      default: "You must provide a body",
    },
  },
};
</script>
<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}
.not-editable {
  font-size: 0.8rem;
  line-height: 30px;
}
input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>
