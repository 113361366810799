<template>
  <v-row v-if="mounted">
    <v-col class="py-4" cols="9">
      <div class="pt-2">
        <span class="font-weight-bold">{{ documentType.NomTypeDocument }}</span>
      </div>
    </v-col>
    <v-col class="text-right py-4" cols="3">
      <v-btn
        class=""
        @click="modalPreviewIsOpen = true"
        fab
        dark
        x-small
        outlined
      >
        <v-icon color="indigo darken-4">mdi-image</v-icon>
        <modalPreviewDocument
          @onClose="modalPreviewIsOpen = false"
          :isOpen="modalPreviewIsOpen"
          v-if="modalPreviewIsOpen"
          :context="{ document: document, documentType: documentType }"
        ></modalPreviewDocument>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import modalPreviewDocument from '@/components/utils/modals/Documents/Document__PreviewDocument.vue';
import { mapActions } from 'vuex';

export default {
  components: { modalPreviewDocument },

  data: () => ({
    modalPreviewIsOpen: false,
    documentsType: [],
    mounted: false,
  }),

  async created() {
    await this.setData();
    console.log(this.document);
    this.mounted = true;
  },

  computed: {
    documentType() {
      return this.documentsType.find(
        (doc) => doc.id == this.document.ID_DocumentType
      );
    },
  },

  methods: {
    ...mapActions('basics', ['getListTypesDocument']),

    async setData() {
      let type = await this.getListTypesDocument();
      this.documentsType = type.filter(
        (doc) =>
          doc.LIST_DocumentCategory?.SlugCategorieDocument == 'documents_club'
      );
      return;
    },
  },

  props: {
    document: {
      type: Object,
    },
  },
};
</script>
