<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <ValidateForm v-if="canComment" @validated="submit">
            <TextArea
              label="Laisser un avis pour la ffme"
              rules="required"
              v-model="item.Commentaire"
            />
            <v-btn
              type="submit"
              :disabled="!hasCodeFederal"
              class="btn btn-primary"
              >Envoyer</v-btn
            >
          </ValidateForm>
          <div v-else>
            Ce commentaire a été laisser par la structure
            {{ item.STR_StructureCommentaire.NomStructure }}
            <v-alert
              border="left"
              color="orange lighten-5"
              icon="info"
              class="mt-4"
              colored-border
              elevation="2"
            >
              {{ item.Commentaire }}
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import TextArea from "@/components/utils/textarea.vue";
import { mapGetters, mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  components: { ValidateForm, TextArea },

  computed: {
    ...mapGetters("structure", ["currentStructureId", "hasCodeFederal"]),

    utilisateur() {
      return JSON.parse(this.item.UTI_Utilisateur);
    },
    adresse() {
      return JSON.parse(this.item.ADR_Adresse);
    },
    canComment() {
      if (
        !this.item.ID_StructureCommentaire ||
        this.currentStructureId == this.item.ID_StructureCommentaire
      ) {
        return true;
      }

      return false;
    },
  },

  methods: {
    ...mapActions("structure", ["editCommentaireTempAffiliation"]),

    async submit() {
      console.log("hello");
      let payload = {
        Status: 1,
        Commentaire: this.item.Commentaire,
        ID_StructureCommentaire: this.currentStructureId,
        id: this.item.id,
      };
      try {
        await this.editCommentaireTempAffiliation({ affiliation: payload });
        success_notification("L'avis a été enregistré");
      } catch (e) {
        console.log(e);
        error_notification();
      }
    },
  },

  props: {
    item: {
      type: Object,
    },
  },
};
</script>
