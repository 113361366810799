<template>
    <div>
        <v-card-text>

           <v-row v-if="mounted">
               <v-col v-for="(doc, index) in documents " :key="index" cols="12" class="py-0">

                   <fileItem :document="doc"/>
                   <hr>
               </v-col>

           </v-row>

        </v-card-text>
    </div>

</template>


<script>

import fileItem from "@/modules/GestionDesAffiliations/PrimoAffiliationDetail_DocumentItem.vue"
export default {

    components: { fileItem },

    async created() {

        this.mounted = true
        return
    },

    data: () => ({
        documentsType: [],
        mounted : false,
        modalPreviewIsOpen: false
    }),

    computed : {
         documents () {
            return this.item.DOC_DocumentsTemp
        },


    },


    props: {
        item: {
            type: Object
        }
    }
}

</script>

<style scoped>
hr {
    margin: 0;
}

</style>
