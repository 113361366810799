<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="5"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.DN_DateNaissance="{ item }">
        {{ $moment(item.DN_DateNaissance).format('DD/MM/YYYY') }}
      </template>
      <template v-slot:item.Z_DateCreationDemande="{ item }">
        <span v-if="item.Z_DateCreationDemande">
          {{ $moment(item.Z_DateCreationDemande).format('DD/MM/YYYY') }}</span
        >
        <span v-else> - </span>
      </template>

      <template v-slot:item.ID_PaysNationalite="{ item }">
        {{
          getList.ADR_Pays.find((pays) => pays.id == item.ID_PaysNationalite)
            .NomPays
        }}
      </template>
      <template v-slot:item.Status="{ item }">
        <span v-if="item.Status == 0"
          ><v-chip small color="primary">En attente de traitement</v-chip></span
        >
        <span v-if="item.Status == 1"
          ><v-chip small color="secondary">En attente de paiement</v-chip></span
        >
        <span v-if="item.Status == 9"
          ><v-chip small color="warning">Paiement accepté</v-chip></span
        >
      </template>

      <template v-slot:item.actions="{ item }">
        <ActionShowUser
          :users="users"
          v-if="hasCodeFederal"
          :currentUser="item"
        ></ActionShowUser>
        <!-- <ActionReAskPayment
          v-if="item.Status == 1 && hasCodeFederal"
          :currentUser="item"
          @sendRelancePayment="sendRelancePaiment"
        ></ActionReAskPayment> -->

        <YesNo
          :showDialog="modalConfirmRelancePaiment"
          v-if="modalConfirmRelancePaiment && hasCodeFederal"
          title="Envoyer des relances de paiement"
          text="Envoyer des relances de paiement à toutes les demandes en attente de plus de 2 semaines ?"
          @answerYes="sendRelancePaiment"
          @answerNo="modalConfirmRelancePaiment = false"
          @close="modalConfirmRelancePaiment = false"
        ></YesNo>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionShowUser from './LicenceHorsClub_ShowTempUser.vue';
import ActionReAskPayment from './LicenceHorsClub_ShowReAskPayment.vue';
import YesNo from '@/components/utils/modals/yes-no.vue';
import { bus } from '@/main';
import { randomStr, send_email } from '@/plugins/utils';
import {
  success_notification,
  error_notification,
} from '@/plugins/notifications';

export default {
  components: { ActionShowUser, /** ActionReAskPayment */ YesNo },
  name: 'GestionDesLicences__HC',

  data: () => ({
    headers: [
      { text: 'Prénom', value: 'CT_Prenom' },
      { text: 'Nom', value: 'CT_Nom' },
      { text: 'Statut', value: 'Status' },
      { text: 'Date de la demande', value: 'Z_DateCreationDemande' },
      { text: 'Actions', value: 'actions' },
    ],
    users: [],
    loading: true,
    mounted: false,
    modalConfirmRelancePaiment: false,
  }),

  async created() {
    this.$emit('setTitle', 'Demandes en attente');

    await this.getListPays();
    await this.setData();
    bus.$on('updateLicenceHorsClub', async () => {
      return await this.setData();
    });
  },

  methods: {
    ...mapActions('utilisateurs', [
      'getUtilisateursTemp',
      'createPaymentTokenHorsClub',
    ]),

    ...mapActions('basics', ['getListPays']),

    async setData() {
      this.users = await this.getUtilisateursTemp();
      // on exclu de la liste ceux qui ont le statut paiement accepté
      // this.users = this.users.filter((user) => user.Status != 9)
      this.loading = false;

      // IF we have users payment pending for more than 2 weeks, show the button to re send payment
      if (this.getUsersRelancePaiment().length) {
        this.$emit('setUserListReady', () => {
          this.modalConfirmRelancePaiment = true;
        });
      } else {
        this.$emit('setUserListReady', null);
      }

      return;
    },

    getUsersRelancePaiment() {
      let users = this.users
        .filter((user) => {
          return user.Status == 1;
        })
        .filter((user) => {
          return this.$moment(user.Z_TokenCreatedAt)
            .add(2, 'weeks')
            .isBefore(this.$moment());
        });
      return users;
    },

    async sendRelancePaiment(user) {
      this.loading = true;
      this.modalConfirmRelancePaiment = false;
      let users = [];

      if (user) {
        users.push(user);
      } else {
        users = this.getUsersRelancePaiment();
      }

      if (users.length) {
        users.map(async (user) => {
          let payload = {
            Z_TokenCreatedAt: this.$moment(),
            PaymentToken: randomStr(36, '12345abcde'),
            id: user.id,
            Status: 1,
            ID_Utilisateur: user.ID_Utilisateur,
          };

          let createToken = await this.createPaymentTokenHorsClub({
            user: payload,
          });
          // SEND EMAI TO THE USER
          // try {}catch(e){}

          let payloadEmail = {
            User: payload.ID_Utilisateur,
            ID_Structure: this.currentStructureId.toString(),
            Context: 'confirmLicenceHorsClub',
            Token: payload.PaymentToken,
            SlugModule:
              'gestion_des_animations_locale__demande_de_licence_hors_club',
          };

          try {
            // payload email
            console.log('--ENVOI MAIL RELANCE');
            let email = await send_email(payloadEmail);
            if (!email.ok) {
              error_notification(
                "Une erreur est survenue lors de l'envoi du mail"
              );
            }
            console.log('--FIN ENVOI MAIL RELANCE');
          } catch (e) {
            console.log('error on email', e);
          }
          // ...
        });

        await this.setData();
        if (users.length > 1) {
          success_notification(
            'Les relances de paiements ont bien été envoyées.'
          );
        } else {
          success_notification('La relance de paiement a bien été envoyée.');
        }
      }
    },
  },

  computed: {
    ...mapGetters('basics', ['getList']),
    ...mapGetters('structure', ['hasCodeFederal', 'currentStructureId']),
  },
};
</script>
