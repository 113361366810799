<template>
  <v-dialog
    v-if="isModalOpen"
    v-model="isModalOpen"
    @click:outside="closeModal"
    max-width="600px"
  >
    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat>
          <div class="text-center px-3 py-6">
            <h6>Êtes vous sur de vouloir annuler cette licence découverte ?</h6>
          </div>
          <v-card-actions class="px-10 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeModal"
              :loading="loading"
              small
              rounded
              color="blue-dark text-white font-weight-bold"
              >Annuler</v-btn
            >
            <v-btn
              @click="removeLicence"
              :loading="loading"
              small
              rounded
              color="blue-dark text-white font-weight-bold"
              >Oui</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import {formatFluxFromLicenceDecouverte, insertFlux} from "@/plugins/fluxService";

export default {
  data: () => ({
    loading: false,
    mounted: false,
  }),

  async created() {
    this.mounted = true;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaisonId"]),
    ...mapGetters("structure", ["currentStructureId"]),
  },

  methods: {
    ...mapActions("utilisateurs", [
      "insertLicenceDecouverte",
      "createFluxFinancierDetail",
    ]),
    ...mapActions("structure", ["updateLicenceDecouverteFluxFinancier"]),
    ...mapActions("basics", ["getTarifsFFME"]),
    closeModal() {
      this.$emit("onClose");
    },
    async removeLicence() {
      try {
        let licenceObj = { ...this.licence };
        delete licenceObj.MKP_FluxFinanciers;
        delete licenceObj.__typename;
        licenceObj.EST_Annulee = true;
        await this.insertLicenceDecouverte({ licence: licenceObj });
        // NEW FLUX BACK FFME
        await insertFlux(formatFluxFromLicenceDecouverte(licenceObj.id, this.currentSaisonId, this.currentStructureId, 'cancel'));
        this.$emit("removed");
        this.closeModal();
        success_notification("La licence découverte a bien été annulée");
      } catch (error) {
        console.log("error:", error);
        error_notification("Une erreur est survenue");
      }
    },
    printFacture(item) {
      this.$refs.facture.printFacture(item);
    },
  },

  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },

    licence: {
      type: Object,
    },
  },
};
</script>
