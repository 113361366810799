<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="openModal"
          class="mx-1"
          v-on="on"
          v-bind="attrs"
          fab
          x-small
          color="blue"
          outlined
        >
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>
      </template>
      <span>Détails de la licence découverte</span>
    </v-tooltip>

    <modalLicenceDecouverte
      v-if="isModalOpen"
      :isModalOpen="isModalOpen"
      :licence="licence"
      @onClose="closeModal"
    />
  </span>
</template>

<script>
import modalLicenceDecouverte from "@/components/utils/modals/Licence/LicenceDecouverte_Modal.vue";
export default {
  components: { modalLicenceDecouverte },
  data: () => ({
    isModalOpen: false,
  }),
  created() {},
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
  props: {
    licence: {
      type: Object,
    },
  },
};
</script>
<style lang="scss">
</style>LicenceDecouverte__ListActionShowLicence