<template>
  <div class="py-10 px-6">
    <ValidateForm @validated="search" :loading="loading" :showFieldObl="false">
      <InputText
        v-model="data.CT_Nom"
        rules="required|min:2|max:250|regexname|dashCounter"
        label="Nom"
      ></InputText>
      <InputText
        v-model="data.CT_Prenom"
        rules="required|min:2|max:250|regexname"
        label="Prenom"
      ></InputText>
      <DatePicker
        v-model="data.DN_DateNaissance"
        :rules="birthdayRules"
        label="Date de naissance"
        @onUpdate="updateDate"
        :maxToday="true"
      ></DatePicker>
      <InputCheckbox
        v-if="!isClub"
        v-model="data.EST_LicenceSiege"
        label="Licence siège"
      ></InputCheckbox>

      <v-btn type="submit" :disabled="!hasCodeFederal" class="btn btn-primary"
        >Rechercher</v-btn
      >
    </ValidateForm>

    <modalNoResult
      v-if="modalNoResultIsOpen"
      :isOpen="modalNoResultIsOpen"
      @onClose="modalNoResultIsOpen = false"
      title="0 résultats"
      body="Désolé, aucuns resultats ne correspond à votre recherche, merci d'en effectuer une nouvelle."
    />
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";
import InputCheckbox from "@/components/utils/checkbox.vue";

// import DatePicker from "@/components/utils/datePicker.vue";
import { mapActions, mapGetters } from "vuex";
import modalNoResult from "@/components/utils/modals/simpleText.vue";

export default {
  components: {
    ValidateForm,
    InputText,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
    modalNoResult,
    InputCheckbox,
  },

  data: () => ({
    data: {
      CT_Nom: "",
      CT_Prenom: "",
      DN_DateNaissance: "",
      EST_LicenceSiege: false,
    },
    loading: false,
    modalNoResultIsOpen: false,
  }),

  computed: {
    ...mapGetters("structure", ["hasCodeFederal", "currentStructureType"]),
    birthdayRules() {
      if (this.isLicenceSiege) return "min:3|max:50";
      return "required|min:3|max:50";
    },

    isClub() {
      return this.currentStructureType.EST_Club;
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["searchUtlisateur"]),

    nextStep() {
      //this.$emit("onNextStep");
    },
    updateDate(date) {
      this.data.DN_DateNaissance = date;
    },

    async search() {
      this.loading = true;
      this.$emit("toggleLoading");
      let where;
      let andCond = [
        {
          UTI_Utilisateur: { CT_Nom: { _ilike: "%" + this.data.CT_Nom + "%" } },
        },
        {
          UTI_Utilisateur: {
            CT_Prenom: { _ilike: "%" + this.data.CT_Prenom + "%" },
          },
        },
      ];
      if (this.isLicenceSiege && !this.data.DN_DateNaissance) {
        if (this.data.EST_LicenceSiege)
          andCond.push({ UTI_Licence: { EST_LicenceSiege: { _eq: true } } });
        where = {
          UTI_Utilisateur: {
            CT_Nom: { _ilike: "%" + this.data.CT_Nom + "%" },
            CT_Prenom: { _ilike: "%" + this.data.CT_Prenom + "%" },
          },
        };
      } else {
        let dateNaissance = this.$moment(this.data.DN_DateNaissance).format(
          "YYYY-MM-DD"
        );
        if (this.data.EST_LicenceSiege)
          andCond.push({ UTI_Licence: { EST_LicenceSiege: { _eq: true } } });
        where = {
          UTI_Utilisateur: {
            DN_DateNaissance: { _eq: dateNaissance },
            CT_Nom: { _ilike: "%" + this.data.CT_Nom + "%" },
            CT_Prenom: { _ilike: "%" + this.data.CT_Prenom + "%" },
          },
        };
      }
      let users = await this.searchUtlisateur({
        where: where,
        // ID_Saison: this.context.ID_Saison
      });
      users.sort(this.sortUserArray);

      this.loading = false;
      this.$emit("toggleLoading");
      return this.$emit("setData", { users: users, search: this.data });
    },
    sortUserArray(user1, user2) {
      let user1Count = 0;
      let user2Count = 0;
      if (
        user1.UTI_Utilisateur.CT_Nom.toLowerCase() ===
        this.data.CT_Nom.toLowerCase()
      )
        user1Count++;
      if (
        user1.UTI_Utilisateur.CT_Prenom.toLowerCase() ===
        this.data.CT_Prenom.toLowerCase()
      )
        user1Count++;
      if (
        this.$moment(user1.UTI_Utilisateur.DN_DateNaissance).format(
          "YYYY-MM-DD"
        ) === this.data.DN_DateNaissance
      )
        user1Count++;
      if (
        user2.UTI_Utilisateur.CT_Nom.toLowerCase() ===
        this.data.CT_Nom.toLowerCase()
      )
        user2Count++;
      if (
        user2.UTI_Utilisateur.CT_Prenom.toLowerCase() ===
        this.data.CT_Prenom.toLowerCase()
      )
        user2Count++;
      if (
        this.$moment(user2.UTI_Utilisateur.DN_DateNaissance).format(
          "YYYY-MM-DD"
        ) === this.data.DN_DateNaissance
      )
        user2Count++;
      return user2Count - user1Count;
    },
  },

  props: {
    context: {
      type: Object,
    },
    isBirthdayRequired: {
      type: Boolean,
      default: true,
    },
    isLicenceSiege: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
