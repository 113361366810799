<template>
  <v-row
    no-gutters
    class="gestion_des_licences__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%">
        <v-col cols="12" class="p-5 licences-adherents-content">
          <v-row style="height: 100%">
            <v-col cols="4">
              <dashboardCard
                module_name="gestion_des_licences__saisir_une_licence"
                title_card="Saisir une nouvelle licence"
                target="_self"
                :custom_link="linkNewLicence"
                auth
                :clubIsAffilier="affiliationstatus || affiliationNextSeasonStatus"
                :blockedAccount="blockedAccount"
              />
            </v-col>

            <v-col cols="4">
              <dashboardCard
                module_name="gestion_des_licences__renouveler_licences_en_serie"
                title_card="Renouveler les licences en série"
                :custom_link="linkRenewSerieLicence"
                target="_self"
                auth
                :clubIsAffilier="affiliationstatus || affiliationNextSeasonStatus"
                :blockedAccount="blockedAccount"
              />
            </v-col>

            <v-col cols="4">
              <dashboardCard
                :custom_link="linkDiscoveryLicence"
                target="_self"
                title_card="Licences découverte"
                auth
                :clubIsAffilier="affiliationstatus"
                :clubIsAffilierCurrentSeason="affiliationstatus"
                :blockedAccount="blockedAccount"
              />
            </v-col>
            <v-col cols="4">
              <dashboardCard
                module_name="affiliations__cotisations_et_tarifs"
                title_card="Adhésion dématérialisée"
                auth
                :clubIsAffilier="affiliationstatus || affiliationNextSeasonStatus"
                :blockedAccount="blockedAccount"
                isComingSoon
              />
            </v-col>

            <v-col cols="4">
              <dashboardCard
                module_name="gestion_des_licences__saisir_les_licences_via_csv"
                title_card="Saisir les licences via fichier CSV"
                auth
                :custom_link="linkLicenceCsv"
                target="_self"
                :clubIsAffilier="affiliationstatus || affiliationNextSeasonStatus"
                :blockedAccount="blockedAccount"
              />
            </v-col>

            <v-col cols="4">
              <dashboardCard
                title_card="HelloAsso"
                subtitle_card="Importer facilement vos adhérents provenant d’HelloAsso"
                auth
                :custom_link="linkHelloAsso"
                target="_self"
                :clubIsAffilier="affiliationstatus || affiliationNextSeasonStatus"
                :blockedAccount="blockedAccount"
              />
            </v-col>

            <!-- <v-col cols="6">
              <dashboardCard
                module_name="gestion_des_animations_locale__liste_licences_hors_club"
                title_card="Gestion des licences hors club"
                auth
              />
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import dashboardCard from '@/components/Common/DashboardCard.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'GestionDesLicences__Dashboard',

  components: { dashboardCard },

  data: () => ({
    loading: true,
    affiliationstatus: true,
    affiliationNextSeasonStatus: false,
  }),
  async created() {
    await this.setAppTitle({ title: 'Gestion des licences' });
    await this.setData();
    this.loading = false;

    // this.affiliationstatus = this.clubIsAffilier;
  },

  methods: {
    ...mapActions('app', ['setAppTitle']),
    ...mapActions('structure', ['getStructureAffiliation']),
    ...mapActions('basics', ['getListEtapeAffiliation']),

    async setData() {
      await this.getListEtapeAffiliation();

      const currentAffiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSaison.id,
      });

      const currentEtapeAffiliation = {
        ...this.getList.LIST_EtapeValidationAffiliation.find(
          (item) => item.id == currentAffiliation.ID_EtapeAffiliation
        ),
      };

      currentEtapeAffiliation.id === 3
        ? (this.affiliationstatus = true)
        : (this.affiliationstatus = false);

      const nextAffiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.nextSaison.id,
      });

      const nextEtapeAffiliation = {
        ...this.getList.LIST_EtapeValidationAffiliation.find(
          (item) => item.id == nextAffiliation.ID_EtapeAffiliation
        ),
      };

      this.affiliationNextSeasonStatus = nextEtapeAffiliation.id === 3;
    },
  },

  computed: {
    ...mapGetters('user', ['currentUserId']),
    ...mapGetters('structure', ['currentStructure', 'currentStructureId']),
    ...mapGetters('saisons', ['saisons', 'currentSaisonId', 'selectedSaison', 'nextSaison']),
    ...mapGetters('basics', ['getList']),

    blockedAccount() {
      return this.currentStructure.STR_Structure.EST_CompteBloque;
    },

    linkDiscoveryLicence() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/utilisateurs?licenceType=licence_decouverte&&structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },

    linkNewLicence() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/saisir-une-licence?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },

    linkRenewSerieLicence() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/renouvellement-en-serie?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },

    linkLicenceCsv() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/saisir-les-licences-via-csv?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },

    linkHelloAsso() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/helloasso?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
  },
};
</script>

<style lang="scss">
.gestion_des_licences__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
