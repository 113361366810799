<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    @click:outside="closeModal"
    max-width="900px"
  >

    <div class="modal__content" v-if="mounted">
        <v-card flat>
          <v-toolbar color="blue-dark" dark>
            <div class="text-center w-100">{{ modalTitle }}</div>
            <v-spacer></v-spacer>
            <v-icon class="close-icon" @click="closeModal">mdi-close</v-icon>
          </v-toolbar><div  class="py-4 px-4">

          <v-alert :color="{0: 'primary', 1: 'secondary', 9: 'warning'}[user.Status]" icon="info" outlined>
            <v-row no-gutters>
              <v-col
                ><span class="font-weight-bold pr-2">Statut:</span>
                <span v-if="user.Status == 0"
                  ><v-chip small color="primary"
                    >En attente de traitement</v-chip
                  ></span
                >
                <span v-if="user.Status == 1"
                  ><v-chip small color="secondary"
                    >En attente de paiement</v-chip
                  ></span
                >
                <span v-if="user.Status == 9"
                  ><v-chip small color="warning">Paiement accepté</v-chip></span
                >
              </v-col>
            </v-row>
          </v-alert>

          <v-row>
            <v-col cols="12"><h5>Coordonnées</h5></v-col>
            <v-col cols="12" md="6">
              <div>
                <span class="font-weight-bold">Nom</span>:
                {{ user.CT_Nom }}
              </div>
              <div>
                <span class="font-weight-bold">Prénom</span>:
                {{ user.CT_Prenom }}
              </div>
              <div>
                <span class="font-weight-bold">Date de naissance</span>:
                {{ $moment(user.DN_DateNaissance).format("DD/MM/YYYY") }}
              </div>
              <div>
                <span class="font-weight-bold">Sexe</span>: {{ civilite }}
              </div>
              <div>
                <span class="font-weight-bold">Nationalité</span>:
                {{ paysNationalite.NomPays }}
                <country-flag
                  v-if="paysNationalite"
                  style="margin: -16px"
                  :country="paysNationalite.CodePays"
                  size="small"
                />
              </div>
              <div>
                <span class="font-weight-bold">Courriel</span>:
                {{ user.CT_Email }}
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <div>
                <span class="font-weight-bold">Adresse</span>:
                {{ adresse.Adresse1 }}
              </div>
              <div>
                <span class="font-weight-bold">Complément adresse</span>:
                {{ adresse.Adresse2 }}
              </div>
              <div>
                <span class="font-weight-bold">Code postal</span>:
                {{ adresse.CodePostal }}
              </div>
              <div>
                <span class="font-weight-bold">Ville</span>: {{ adresse.Ville }}
              </div>
              <div>
                <span class="font-weight-bold">Département</span>:
                {{ userDepartement }}
              </div>
              <div>
                <span class="font-weight-bold">Pays</span>:
                {{ adresse.ID_Pays.NomPays }}
                <country-flag
                  v-if="paysAdresse"
                  style="margin: -16px"
                  :country="paysAdresse.CodePays"
                  size="small"
                />
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12"><h5>Licence et assurances</h5></v-col>
            <v-col cols="12" md="12">
              <div v-if="licenceType">
                <span class="font-weight-bold">Type de licence</span>:
                <v-chip small class="mx-1 mt-1">
                  {{
                    `${licenceType.label} (${(prices.find((price) => price.product && price.product.id === licenceType.id).value) / 100} €)`
                  }}
                </v-chip>
              </div>
              <div v-if="selectedAssurance">
                <span class="font-weight-bold">Assurance</span>:
                <v-chip small class="mx-1">{{
                  `${selectedAssurance.label} (${(prices.find((price) => price.option && price.option.id === selectedAssurance.id).value) / 100} €)`
                }}</v-chip>
              </div>
              <div v-if="assuranceOptions">
                <span class="font-weight-bold">Option(s) d'assurance</span>:
                <optionAssuranceChips
                  v-for="(option) in assuranceOptions"
                  :key="option.id"
                  :option="option"
                  :price="prices.find((price) => price.option && price.option.id === option.id)"
                />
              </div>
              <div class="mt-2">
                <span class="font-weight-bold">MONTANT PANIER: </span>
                <span class="font-weight-bold"> {{ montantTotal / 100 }} € </span>
              </div>
              <div class="mt-2">
                <span class="font-weight-bold">FRAIS BANCAIRES: </span>
                <span class="font-weight-bold">
                  {{ commissionLemonway / 100 }} €
                </span>
              </div>
              <div class="mt-2">
                <span class="font-weight-bold">MONTANT TOTAL: </span>
                <span class="font-weight-bold"> {{ totalPanier / 100 }} € </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"><h5>Certificat médical</h5></v-col>

            <v-col v-if="user.ID_CertificatMedical" cols="12" md="12">
              <div>
                <span class="font-weight-bold">Type de certificat</span>:
                {{ certificatType.replace("Certificat médical : ", "") }}
              </div>
              <div v-if="user.ID_CertificatMedical !== 60">
                <span class="font-weight-bold">Certificat</span>:
                <a :href="document.CheminDocument" target="_blanck"
                  >{{ document.NomDocument }}
                </a>
              </div>
              <div v-if="user.ID_CertificatMedical !== 60">
                <span class="font-weight-bold">Date de délivrance</span>:
                {{
                  $moment(document.DateFinValiditeDocument).format("DD/MM/YYYY")
                }}
              </div>
            </v-col>
          </v-row>
          <v-row v-if="this.user.Status !== 9">
            <v-col>
              <v-tooltip right :disabled="!isRemindedLast24Hours">
                <template v-slot:activator="{ on }">
                  <div style="width: fit-content;" v-on="on">
                    <v-btn
                      class="btn btn-primary"
                      :disabled="!hasCodeFederal || isRemindedLast24Hours"
                      :loading="loading"
                      @click="confirm"
                    >
                      {{ confirmButtonText }}
                    </v-btn>
                  </div>
                </template>
                <span>Un email de relance a déjà été envoyé dans les dernières 24 heures</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-card>

    </div>

    <ModalConfirm
      :showDialog="modalConfirmIsOpen"
      :text="textModal"
      text_no="Annuler"
      @answerYes="submit"
      @answerNo="modalConfirmIsOpen = false"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";
import optionAssuranceChips from "@/views/LicenceHorsClub/assuranceChips.vue";
import ModalConfirm from "@/components/utils/modals/yes-no.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import { getTransactionLicenceHCBody, insertTransaction, OPTION_TYPE_ASSURANCE } from '@/plugins/transactionService';
import { send_email } from '@/plugins/utils';
import {
  PRODUCT_CATEGORY_LICENCE,
  getProducts,
  getOptions,
  getPrices
} from "@/plugins/productService";


import { bus } from "@/main";
export default {
  components: { CountryFlag, optionAssuranceChips, ModalConfirm },

  data: () => ({
    loading: false,
    mounted: false,
    adresse: {},
    licence: {},
    assurances: {},
    modalConfirmIsOpen: false,
    departementsList: [],
  }),

  async created() {
    await this.getListPays();
    this.departementsList = await this.getDepartementsList();
    this.adresse = JSON.parse(this.user.ADR_Adresse);
    this.licence = this.user.UTI_Licence;
    this.document = this.user.DOC_DocumentsTemp[0];
    this.assurances = JSON.parse(this.user.UTI_LicenceAssurances);

    this.products = await getProducts({
      'productCategorie.slug': PRODUCT_CATEGORY_LICENCE,
      'order[order]': 'asc',
    });

    this.options = await getOptions({
      'order[order]': 'asc',
    });

    this.prices = await getPrices({
      'itemsPerPage': 1000,
      'seasonId': this.currentSaison.id,
      'structureId': 1451,
      'productId': this.licence,
      'options': this.assurances.join(','),
    });

    this.mounted = true;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("user", ["currentUserId"]),
    ...mapGetters("structure", ["currentStructureId", "hasCodeFederal"]),

    textModal() {
      if (this.user.Status === 0) {
        return "Valider la demande de licence ?";
      } else if (this.user.Status === 1) {
        return "Envoyer une relance de paiement ?";
      }

      return "";
    },

    isRemindedLast24Hours() {
      if (!this.user.last_reminder_date) {
        return false;
      }
      return this.$moment(this.user.last_reminder_date).isSameOrAfter(this.$moment().subtract(24, 'hours'));
    },

    modalTitle() {
      return "Demande de licence hors club";
    },
    confirmButtonText() {
      if (this.user.Status === 0) {
        return "Valider la demande de licence";
      } else if (this.user.Status === 1) {
        return "Relance de paiement";
      }

      return '';
    },
    civilite() {
      if (this.user.EST_Civilite == 1) return "Homme";
      if (this.user.EST_Civilite == 0) return "Femme";
      return "";
    },

    paysNationalite() {
      if (!this.user.ID_PaysNationalite) return "";
      return this.getList.ADR_Pays.find(
        (pays) => pays.id == this.user.ID_PaysNationalite
      );
    },

    paysAdresse() {
      if (!this.adresse.ID_Pays) return "";
      return this.getList.ADR_Pays.find(
        (pays) => pays.id == this.adresse.ID_Pays
      );
    },

    licenceType() {
      return this.products.find((product) => product.id === this.licence);
    },

    selectedAssurance() {
      return this.options.find((option) =>
        this.assurances.includes(option.id) && option.optionType.slug === OPTION_TYPE_ASSURANCE
      );
    },

    assuranceOptions() {
      return this.options.filter((option) =>
        this.assurances.includes(option.id) && option.optionType.slug !== OPTION_TYPE_ASSURANCE
      );
    },

    certificatType() {
      if (!this.user.ID_CertificatMedical) return "";
      return this.getList.LIST_DocumentType.find(
        (cm) => cm.id == this.user.ID_CertificatMedical
      ).NomTypeDocument;
    },

    userDepartement() {
      if (!this.user.ID_Departement) return "-";
      return this.departementsList.find(
        (dpt) => dpt.id == this.user.ID_Departement
      ).NomDepartement;
    },

    montantTotal() {
      let price = 0;

      if (this.licenceType) {
        price += this.prices.find(
          (price) => price.product && price.product.id === this.licenceType.id
        ).value;
      }

      if (this.selectedAssurance) {
        price += this.prices.find(
          (price) => price.option && price.option.id === this.selectedAssurance.id
        ).value;
      }

      if (this.assuranceOptions) {
        this.assuranceOptions.forEach((option) => {
          price += this.prices.find(
            (price) => price.option && price.option.id === option.id
          ).value;
        });
      }
      return price;
    },

    totalPanier() {
      if (!this.montantTotal) {
        return 0;
      }

      return Math.ceil(this.montantTotal + this.commissionLemonway);
    },

    commissionLemonway() {
      return Math.ceil((this.montantTotal * 0.0072) + 16);
    },
  },

  methods: {
    ...mapActions("basics", [
      "getListPays",
      "getDepartementsList",
    ]),
    ...mapActions("utilisateurs", [
      "updateTempUserReminderDate"
    ]),
    closeModal() {
      this.$emit("onClose");
    },

    confirm() {
      this.modalConfirmIsOpen = true;
    },

    async submit() {
      this.modalConfirmIsOpen = false;
      this.loading = true;

      if (this.user.Status === 0) {
        const transaction = await insertTransaction(
          getTransactionLicenceHCBody(
            this.totalPanier,
            this.licence,
            this.assurances,
            this.currentSaison.id,
            this.user.id,
          )
        );

        if (!transaction.ok) {
          error_notification('Une erreur est survenue lors de la création de la transaction');
          this.loading = false;
          return;
        }

        success_notification(
          "L'utilisateur a été créé et le lien de paiement a été renvoyé a " + this.user.CT_Email
        );
      } else if (this.user.Status === 1) {
        let payloadEmail = {
          User: this.user.ID_Utilisateur,
          Context: "licenceHCFollowUp",
          Email: this.user.CT_Email,
        };

        await send_email(payloadEmail);

        this.user.last_reminder_date = new Date().toISOString();

        this.updateTempUserReminderDate({
          userId: this.user.id,
          lastReminderDate: this.user.last_reminder_date,
        });

        success_notification(
          "Un email de relance a été envoyé a " + this.user.CT_Email
        );
      }

      bus.$emit("updateLicenceHorsClub");
      this.$emit("onClose");

      this.loading = false;

      return;
    },
  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },

    user: {
      type: Object,
    },
  },
};
</script>

<style scoped>
h5 {
  background-color: rgb(218, 218, 218);
  padding: 10px;
}
</style>
