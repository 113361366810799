var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('SubBar',{attrs:{"title":_vm.subBarTitle,"route_name":"gestion_des_structures"}})],1),_c('v-data-table',{staticClass:"elevation-1 list__table",attrs:{"headers":_vm.headers,"items":_vm.affiliations,"items-per-page":20,"height":"500px"},scopedSlots:_vm._u([{key:"item.STR_NomStructure",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.STR_NomStructure)+" ")]}},{key:"item.Z_DateCreationDemande",fn:function(ref){
var item = ref.item;
return [(item.Z_DateCreationDemande)?_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.Z_DateCreationDemande).format('DD/MM/YYYY')))]):_c('span',[_vm._v(" - ")])]}},{key:"item.ID_TypeStructure",fn:function(ref){
var item = ref.item;
return [(
          item.ID_TypeStructure &&
            _vm.allStructureType.find(function (s) { return s.id == item.ID_TypeStructure; })
        )?_c('span',[_vm._v(" "+_vm._s(_vm.allStructureType.find(function (s) { return s.id == item.ID_TypeStructure; }) .NomTypeStructure)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.Status",fn:function(ref){
        var item = ref.item;
return [(item.Status == 0)?_c('span',[_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v("Demande en attente")])],1):_vm._e(),(item.Status == 1)?_c('span',[_c('v-chip',{attrs:{"small":"","color":"secondary"}},[_vm._v("Avis donné")])],1):_vm._e(),(item.Status == 2)?_c('span',[_c('v-chip',{attrs:{"small":"","color":"warning"}},[_vm._v("Demande acceptée")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.hasCodeFederal)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('router-link',{attrs:{"to":{
              name: 'gestion_des_affiliations__primo_affiliation_detail',
              params: { id: item.id },
            }}},[_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"blue"}},on),[_c('v-icon',{attrs:{"color":'white'}},[_vm._v("mdi-open-in-new")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Voir la demande")])]):_vm._e(),(_vm.currentStructureId === 1318)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"red"},on:{"click":function () {
              _vm.itemToDelete = item.id;
              _vm.confirmDialog = true;
            }}},on),[_c('v-icon',{attrs:{"color":'white'}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer la demande")])]):_vm._e()]}}])}),_c('YesNo',{attrs:{"id":"yesno-dialog","title":"CONFIRMATION","text":"Souhaitez-vous vraiment supprimer cette demande d'affiliation ?","maxWidth":"360","showDialog":_vm.confirmDialog},on:{"answerYes":_vm.removeTempAffiliation,"answerNo":function($event){_vm.confirmDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }