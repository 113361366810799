<template>
  <ul
    class="moncompte__navigation-body d-flex flex-column justify-space-between text-center pl-0"
  >
    <li>
      <router-link
        :to="{
          name: 'gestion_des_animations_locale__liste_licences_hors_club',
        }"
        >Liste des licences hors club</router-link
      >
    </li>
    <li>
      <router-link
        :to="{
          name: 'gestion_des_animations_locale__demande_de_licence_hors_club',
        }"
        >Demandes en attente</router-link
      >
    </li>
    <!-- <li><router-link :to="{name : 'mon_compte__activitees_pratiques'}">Activitées pratiquées</router-link></li> -->
    <!-- <li><router-link :to="{name : 'mon_compte__notification'}">Paramètrage des notifications</router-link></li> -->
  </ul>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
ul {
  margin-bottom: 0;
}
</style>
