<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_des_affiliations__demandes_primo_affiliations"
      />
    </v-row>

    <v-row v-if="!mounted">
      Chargement...
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-card>
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab v-for="item in itemsTabs" :key="item.tab">
              {{ item.tab }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in itemsTabs" :key="item.tab">
              <v-card flat>
                <component :is="item.content" :item="affiliation" />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubBar from '@/components/Common/Common__SubBar.vue';

import DemandePrimo_InfoGeneral from '@/modules/GestionDesAffiliations/PrimoAffiliationDetail_InfoGeneral.vue';
import DemandePrimo_Contact from '@/modules/GestionDesAffiliations/PrimoAffiliationDetail_Contact.vue';
// import DemandePrimo_Tarifs from "@/modules/GestionDesAffiliations/PrimoAffiliationDetail_Tarifs.vue";
import DemandePrimo_Documents from '@/modules/GestionDesAffiliations/PrimoAffiliationDetail_Documents.vue';
import DemandePrimo_Avis from '@/modules/GestionDesAffiliations/PrimoAffiliationDetail_Avis.vue';
import DemandePrimo_Affiliation from '@/modules/GestionDesAffiliations/PrimoAffiliationDetail_Validation.vue';
import DemandePrimo_Rib from '@/modules/GestionDesAffiliations/PrimoAffiliationDetail_Rib.vue';

import { mapActions, mapGetters } from 'vuex';
import router from '@/router';
export default {
  components: {
    SubBar,
    DemandePrimo_InfoGeneral,
    DemandePrimo_Contact,
    DemandePrimo_Documents,
    DemandePrimo_Avis,
    DemandePrimo_Affiliation,
    DemandePrimo_Rib,
  },

  data: () => ({
    affiliation: {},
    mounted: false,
    tab: null,
    itemsTabs: [
      { tab: "Informations générales", content: "DemandePrimo_InfoGeneral" },
      { tab: "Contact", content: "DemandePrimo_Contact" },
      //   { tab: 'Tarifs', content: 'DemandePrimo_Tarifs' },
      { tab: 'Documents', content: 'DemandePrimo_Documents' },
      { tab: 'RIB', content: 'DemandePrimo_Rib' },
      { tab: 'Avis', content: 'DemandePrimo_Avis' },
    ],
  }),

  async created() {
    await this.setData();
    if (this.isFFME) {
      this.itemsTabs.push({
        tab: 'Validation',
        content: 'DemandePrimo_Affiliation',
      });
    }
    console.log(this.$refs);
  },

  computed: {
    ...mapGetters('structure', ['currentStructure']),

    subBarTitle() {
      if (this.affiliation.STR_NomStructure) {
        return 'Demande Primo Affiliation ' + this.affiliation.STR_NomStructure;
      } else {
        return 'Demande Primo Affiliation';
      }
    },

    isFFME() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == 'service_federal_ffme';
    },
  },

  methods: {
    ...mapActions('structure', ['getSingleAffiliationTemp']),

    async setData() {
      let regex = new RegExp('^[0-9]*$');

      if (!regex.test(this.$route.params.id)) {
        return router.push({
          name: 'gestion_des_affiliations__demandes_primo_affiliations',
        });
      }

      // Les choses commencent ici pour toi @Alban
      var id = parseInt(this.$route.params.id);
      await this.getSingleAffiliationTemp({ ID_AffiliationTemp: id }).then(
        (data) => {
          if (
            data.STR_AffiliationTemp[0] &&
            data.STR_AffiliationTemp[0].Status != 2
          ) {
            this.affiliation = data.STR_AffiliationTemp[0];
            // console.log('affi', {...this.affiliation})
            this.mounted = true;
          } else {
            router.push({
              name: 'gestion_des_affiliations__demandes_primo_affiliations',
            });
          }
        }
      );
      return;
    },
  },
};
</script>
