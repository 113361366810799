<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="openModal"
          class="mx-1"
          v-on="on"
          v-bind="attrs"
          fab
          x-small
          color="blue"
          outlined
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Modifier la licence découverte</span>
    </v-tooltip>

    <modalLicenceDecouverte
      isUpdate
      v-if="isModalOpen"
      :isModalOpen="isModalOpen"
      :licence="licence"
      @onUpdated="updated"
      @onClose="closeModal"
    />
  </span>
</template>

<script>
import modalLicenceDecouverte from '@/components/utils/modals/Licence/LicenceDecouverte_Modal.vue';
export default {
  components: { modalLicenceDecouverte },
  data: () => ({
    isModalOpen: false,
  }),
  created() {},
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    updated() {
      this.$emit('onUpdated');
    },
  },
  props: {
    licence: {
      type: Object,
    },
  },
};
</script>
<style lang="scss"></style>
