<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <h6>Correspondant :</h6>
          <div>
            <span class="font-weight-bold">Nom : </span>
            {{
              correspondant.CT_Nom
                ? correspondant.CT_Nom
                : correspondant.representantFirstName
            }}
          </div>
          <div>
            <span class="font-weight-bold">Prénom : </span>
            {{
              correspondant.CT_Prenom
                ? correspondant.CT_Prenom
                : correspondant.representantLastName
            }}
          </div>
          <div>
            <span class="font-weight-bold">Email : </span>
            {{
              correspondant.CT_Email
                ? correspondant.CT_Email
                : correspondant.representantEmail
            }}
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <h6>Adresse de correspondance :</h6>
          <div>
            <span class="font-weight-bold">Adresse : </span>
            {{ adresse.Adresse1 ? adresse.Adresse1 : adresse.adress }}
          </div>
          <div>
            <span class="font-weight-bold">Adresse comp. : </span>
            {{ adresse.Adresse2 ? adresse.Adresse2 : adresse.adressSupp }}
          </div>
          <div>
            <span class="font-weight-bold">Ville : </span>
            {{ adresse.Ville ? adresse.Ville : adresse.country }}
          </div>
          <div>
            <span class="font-weight-bold">Code Postal : </span>
            {{ adresse.CodePostal }}
          </div>
        </v-col>
      </v-row>

      <v-row v-if="this.item.ID_TypeStructure !== 6 && this.item.ID_TypeStructure !== 3">
        <v-col cols="12" md="4">
          <h6>Président:</h6>
          <div>
            <span class="font-weight-bold">Nom : </span> {{ president.CT_Nom }}
          </div>
          <div>
            <span class="font-weight-bold">Prénom : </span>
            {{ president.CT_Prenom }}
          </div>
          <div>
            <span class="font-weight-bold">Email : </span>
            {{ president.CT_Email }}
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <h6>Secrétaire:</h6>
          <div>
            <span class="font-weight-bold">Nom : </span> {{ secretaire.CT_Nom }}
          </div>
          <div>
            <span class="font-weight-bold">Prénom : </span>
            {{ secretaire.CT_Prenom }}
          </div>
          <div>
            <span class="font-weight-bold">Email : </span>
            {{ secretaire.CT_Email }}
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <h6>Trésorier:</h6>
          <div>
            <span class="font-weight-bold">Nom : </span> {{ tresorier.CT_Nom }}
          </div>
          <div>
            <span class="font-weight-bold">Prénom : </span>
            {{ tresorier.CT_Prenom }}
          </div>
          <div>
            <span class="font-weight-bold">Email : </span>
            {{ tresorier.CT_Email }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {},

  computed: {
    correspondant() {
      if (this.item.ID_TypeStructure === 6) {
        if (
          this.item.UTI_Utilisateur &&
          JSON.parse(this.item.UTI_Utilisateur)
        ) {
          return JSON.parse(this.item.UTI_Utilisateur);
        }
      } else {
        if (
          this.item.UTI_Utilisateur &&
          JSON.parse(this.item.UTI_Utilisateur).correspondant
        ) {
          return JSON.parse(this.item.UTI_Utilisateur).correspondant;
        }
      }
      return false;
    },

    president() {
      if (
        this.item.UTI_Utilisateur &&
        JSON.parse(this.item.UTI_Utilisateur).president
      ) {
        return JSON.parse(this.item.UTI_Utilisateur).president;
      }
      return false;
    },

    secretaire() {
      if (
        this.item.UTI_Utilisateur &&
        JSON.parse(this.item.UTI_Utilisateur).secretaire
      ) {
        return JSON.parse(this.item.UTI_Utilisateur).secretaire;
      }
      return false;
    },

    tresorier() {
      if (
        this.item.UTI_Utilisateur &&
        JSON.parse(this.item.UTI_Utilisateur).tresorier
      ) {
        return JSON.parse(this.item.UTI_Utilisateur).tresorier;
      }
      return false;
    },

    adresse() {
      return JSON.parse(this.item.ADR_Adresse);
    },
  },

  created() {
    console.log(JSON.parse(this.item.UTI_Utilisateur).correspondant);
  },

  props: {
    item: {
      type: Object,
    },
  },
};
</script>
