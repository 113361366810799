<template>
  <span>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click="openModal"
          class="mx-1"
          fab
          x-small
          outlined
          color="blue"
        >
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>
      </template>
      <span>Voir les informations de l'utilisateur</span>
    </v-tooltip>

    <modalUserDetail
      v-if="openModalShowUser && mounted"
      :modalIsOpen="openModalShowUser"
      :user="currentUser"
      @onClose="closeModal"
      @onUpdate="onUpdate"
      :areNextPrevDisabled="!(users.length >1)"
    />
  </span>
</template>

<script>
import modalUserDetail from "@/components/utils/modals/Licence/LicenceHorsClubShowTempUser__Modal.vue";

export default {
  components: { modalUserDetail },

  data: () => ({
    loading: false,
    mounted: false,
    openModalShowUser: false,
  }),

  async created() {
   
    this.mounted = true;
  },

  computed: {},

  methods: {
    onUpdate(toklen) {
      console.log('updatge', toklen)
      //this.$emit('onUpdate')
    },

    closeModal() {
      this.openModalShowUser = false;
    },

    openModal() {
      
      this.openModalShowUser = true;
    },
  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },

    currentUser: {
      type: Object,
    },
    users: {
      type: Array,
    },
  },
};
</script>
