<template>
  <v-row
    no-gutters
    class="gestion_des_affiliations__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="p-4">
          <v-row style="height: 100%;">
            <v-col cols="6">
              <dashboardCard
                module_name="gestion_des_affiliations__demandes_primo_affiliations"
                title_card="Demande de primo affiliations"
                auth
              />
            </v-col>
<!-- 
            <v-col cols="6">
              <dashboardCard
                module_name="gestion_des_licences__renouveler_licences_en_serie"
                title_card="Renouveler les licences en série"
                auth
              />
            </v-col> -->

     
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import dashboardCard from "@/components/Common/DashboardCard.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "GestionDesAffiliations__Dashboard",

  components: { dashboardCard },

  data: () => ({
    loading: true,
  }),
  async created() {
    await this.setAppTitle({ title: "Gestion des affiliations" });
    this.loading = false;
  },

  methods: {
    ...mapActions("app", ["setAppTitle"]),
  },

  computed: {
    ...mapGetters("user", ["currentUserId"]),
  },
};
</script>

<style lang="scss">
.gestion_des_afiliations__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
