<template>
  <span>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click="openModal"
          class="mx-1"
          fab
          x-small
          outlined
          color="blue"
        >
          <v-icon>mdi-credit-card-outline</v-icon>
        </v-btn>
      </template>
      <span>Relancer le paiement par mail</span>
    </v-tooltip>
<ModalConfirm
        :showDialog="modalConfirmIsOpen"
        text="Relancer l'utilisateur sur ce paiement ?"
        text_no="Annuler"
        @answerYes="submit"
        @answerNo="modalConfirmIsOpen = false"
      />
  </span>
</template>

<script>
import ModalConfirm from "@/components/utils/modals/yes-no.vue";
import {bus} from "@/main"
import { mapActions, mapGetters } from "vuex";
import { randomStr } from "@/plugins/utils";

import {error_notification, success_notification } from "@/plugins/notifications"

export default {
  components: {  ModalConfirm },

  data: () => ({
    loading: false,
    mounted: false,
    modalConfirmIsOpen: false,
  }),

  async created() {
    this.mounted = true;
  },

  computed: {},

  methods: {
    ...mapActions("utilisateurs", ["createPaymentTokenHorsClub",]),


    closeModal() {
      this.modalConfirmIsOpen = false;
    },

    openModal() {
      this.modalConfirmIsOpen = true;
    },
    async submit() {
      this.$emit('sendRelancePayment', this.currentUser)
      this.closeModal()
    },

  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },

    currentUser: {
      type: Object,
    },
  },
};
</script>
