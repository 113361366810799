<template>
  <v-dialog
    v-if="isModalOpen"
    v-model="isModalOpen"
    @click:outside="onClose"
    max-width="600px"
  >
    <div
      class="modal__content licence-decouverte-show"
      style="background: #fff !important; padding:1em"
    >
      <ValidateForm
        @validated="submit"
        :showFieldObl="isUpdate ? true : false"
        :loading="loading"
        style="width: 100%;"
      >
        <v-row>
          <v-col cols="12">
            <h5>Caractéristiques:</h5>

            <DatePicker
              v-model="data.LicenceDateDebut"
              label="Date de début de validité:"
              :minToday="true"
              :rules="isUpdate ? 'required' : ''"
              :key="refreshDates + 1"
              :disabled="!isUpdate"
            />
            <InputSelect
              :class="[!isUpdate ? 'select-disabled' : '', 'force-w-100']"
              v-model="data.LicenceNbJour"
              no_filter
              :items="nbDays"
              label="Nombre de jours"
              placeholder="Sélectionnez un nombre de jours"
              :rules="isUpdate ? 'required' : ''"
              :disabled="!isUpdate"
            ></InputSelect>
          </v-col>
          <v-col>
            <h5>Informations sur le porteur:</h5>

            <v-row>
              <v-col cols="12">
                <inputText
                  label="Nom:"
                  :rules="isUpdate ? 'required|min:2|max:250|regexname|dashCounter' : 'min:2|max:250|regexname|dashCounter' "
                  v-model="data.CT_Nom"
                  :disabled="!isUpdate"
                />
                <inputText
                  label="Prénom:"
                  :rules="isUpdate ? 'required|min:2|max:250|regexname' : 'min:2|max:250|regexname' "
                  v-model="data.CT_Prenom"
                  :disabled="!isUpdate"
                />

                <DatePicker
                  v-model="data.CT_DateNaissance"
                  label="Date de naissance:"
                  :rules="isUpdate ? 'required' : ''"
                  :maxDate="
                    $moment()
                      .subtract(1, 'days')
                      .format('YYYY-MM-DD')
                  "
                  :key="refreshDates + 2"
                  :disabled="!isUpdate"
                />

                <inputText
                  v-model="data.CT_Email"
                  :rules="isUpdate ? 'email' : ''"
                  label="Adresse courriel"
                  :disabled="!isUpdate"
                ></inputText>

                <!-- <countriesSelect v-model="data.currentPays" label="Pays" @onChange="onPaysChanged"></countriesSelect> -->

                <AddressAutocomplete
                  :item="data.AD_Adresse1"
                  rules="min:6|max:50"
                  label="Adresse"
                  :minimum_characters="3"
                  ref="adresseAuto"
                  @onSelected="adresseSelected"
                  :restrictCountry="restrictCountry"
                  :disabled="!isUpdate"
                  class="force-w-100"
                ></AddressAutocomplete>

                <inputText
                  v-model="data.AD_Adresse2"
                  rules="min:0|max:50"
                  label="Complément d'adresse"
                  :disabled="!isUpdate"
                />

                <inputText
                  v-model="data.AD_CodePostal"
                  :key="refreshCodePostal"
                  rules="digits:5"
                  label="Code postal"
                  :disabled="!isUpdate"
                />

                <inputText
                  v-model="data.AD_Ville"
                  :key="refreshVille"
                  rules="min:1|max:60"
                  label="Ville"
                  :disabled="!isUpdate"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions v-if="isUpdate" class>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            :loading="loading"
            rounded
            color="blue-dark text-white font-weight-bold"
            >Enregistrer</v-btn
          >
        </v-card-actions>
      </ValidateForm>
    </div>
  </v-dialog>
</template>

<script>
import inputText from '@/components/utils/input.vue';
import DatePicker from '@/components/utils/datePicker.vue';
import ValidateForm from '@/components/utils/form.vue';
import AddressAutocomplete from '@/components/utils/adresseAutocomplete';
// import countriesSelect from "@/components/utils/select__countries.vue";
import InputSelect from '@/components/utils/select.vue';
import { sleep, send_email } from '@/plugins/utils';
import { mapActions, mapGetters } from 'vuex';
import {
  success_notification,
  error_notification,
} from '@/plugins/notifications';
import {
  formatFluxFromUpdateLicenceDecouverte,
  insertFlux
} from "@/plugins/fluxService";

export default {
  components: {
    DatePicker,
    inputText,
    AddressAutocomplete,
    // countriesSelect,
    InputSelect,
    ValidateForm,
  },
  props: {
    isModalOpen: { type: Boolean },
    isUpdate: { type: Boolean },
    licence: { type: Object },
  },
  data: () => ({
    nbDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    restrictCountry: 'FR',
    dateFinIsDisabled: true,
    refreshCodePostal: 0,
    refreshVille: 99,
    data: {},
    refreshDates: 0,
    loading: false,
  }),
  created() {
    this.setData();
  },
  computed: {
    ...mapGetters('saisons', ['currentSaisonId']),
    ...mapGetters('structure', ['currentStructureId']),
  },
  methods: {
    ...mapActions('utilisateurs', [
      'insertLicenceDecouverte',
      'createFluxFinancierDetail',
    ]),
    ...mapActions('structure', [
      'getLicenceDecouverteTarif',
      'updateLicenceDecouverteFluxFinancier',
    ]),
    ...mapActions('basics', ['getTarifsFFME']),
    async adresseSelected(item) {
      if (typeof item === 'string') return (this.data.Adresse1 = item);
      await sleep(10);
      let adresse1 = item[0].long_name + ' ' + item[1].long_name;
      this.$refs.adresseAuto.currentValue = adresse1.toUpperCase();
      this.data.AD_Adresse1 = adresse1.toUpperCase();
      this.data.AD_Adresse2 = '';
      this.data.AD_Ville = item[2].long_name.toUpperCase();
      this.data.AD_CodePostal = item[6] ? item[6].long_name.toUpperCase() : '';
      // console.log( this.userAdresse)
      this.refreshCodePostal++;
      this.refreshVille++;
    },
    setData() {
      this.data = { ...this.licence };
      console.log('this.data', this.data);
    },
    onClose() {
      this.$emit('onClose');
    },
    async submit() {
      // console.log("aleeeeeeeeeeeeed", this.data);
      if (JSON.stringify(this.data) === JSON.stringify(this.licence)) {
        return;
      }
      this.loading = true;
      try {
        if (this.data.LicenceNbJour === this.licence.LicenceNbJour) {
          delete this.data.MKP_FluxFinanciers;
          delete this.data.__typename;
          await this.insertLicenceDecouverte({ licence: this.data });
        } else if (this.data.LicenceNbJour > this.licence.LicenceNbJour) {
          delete this.data.MKP_FluxFinanciers;
          delete this.data.__typename;
          const MKP_Tarif = (
            await this.getTarifsFFME({ ID_Saison: this.currentSaisonId })
          ).find(
            (tarif) =>
              tarif.LIST_TarifCategorie.SlugCategorieTarif ===
              'licence_decouverte'
          );
          if (!MKP_Tarif)
            return error_notification(
              'Aucun tarif pour la saison associée: ' + this.currentSaisonId
            );
          let fluxStructure = {
            ID_FluxFinancierDebit: 0,
            ID_Saison: this.currentSaisonId,
            ID_Structure: this.currentStructureId,
            ID_Traitement: 0,
            ID_Utilisateur: null,
            MontantHT:
              MKP_Tarif.MontantUnitaire *
              (this.data.LicenceNbJour - this.licence.LicenceNbJour),
            MontantTTC:
              MKP_Tarif.MontantUnitaire *
              (this.data.LicenceNbJour - this.licence.LicenceNbJour),
            MontantTVA: 1,
            NomFluxFinancier: `Licence découverte ${this.data.CT_Nom} ${this.data.CT_Prenom} ${this.currentSaisonId}`,
            EST_Reglement: false,
            ID_LicenceDecouverte: this.licence.id,
            MKP_FluxFinancierDetail: {
              data: [
                {
                  ID_Structure: this.currentStructureId,
                  ID_Utilisateur: null,
                  ID_Saison: this.currentSaisonId,
                  ID_Tarif: MKP_Tarif.id,
                  MontantHT:
                    MKP_Tarif.MontantUnitaire *
                    (this.data.LicenceNbJour - this.licence.LicenceNbJour),
                  MontantTTC:
                    MKP_Tarif.MontantUnitaire *
                    (this.data.LicenceNbJour - this.licence.LicenceNbJour),
                  MontantTVA: 1,
                  FIN_CodeAnalytique: 0,
                  FIN_CodeCompta: 0,
                  NomFluxFinancierDetail: `Licence découverte ${this.data.CT_Nom} ${this.data.CT_Prenom} ${this.currentSaisonId}`,
                  PrixUnitaire: MKP_Tarif.MontantUnitaire,
                  Quantite:
                    this.data.LicenceNbJour - this.licence.LicenceNbJour,
                },
              ],
            },
          };
          await this.insertLicenceDecouverte({ licence: this.data });
          await this.createFluxFinancierDetail({ flux: fluxStructure });
        } else if (this.data.LicenceNbJour < this.licence.LicenceNbJour) {
          delete this.data.MKP_FluxFinanciers;
          delete this.data.__typename;
          const MKP_Tarif = (
            await this.getTarifsFFME({ ID_Saison: this.currentSaisonId })
          ).find(
            (tarif) =>
              tarif.LIST_TarifCategorie.SlugCategorieTarif ===
              'licence_decouverte'
          );
          if (!MKP_Tarif)
            return error_notification(
              'Aucun tarif pour la saison associée: ' + this.currentSaisonId
            );
          let fluxStructure = {
            ID_FluxFinancierDebit: 0,
            ID_Saison: this.currentSaisonId,
            ID_Structure: this.currentStructureId,
            ID_Traitement: 0,
            ID_Utilisateur: null,
            MontantHT:
              MKP_Tarif.MontantUnitaire *
              (this.licence.LicenceNbJour - this.data.LicenceNbJour),
            MontantTTC:
              MKP_Tarif.MontantUnitaire *
              (this.licence.LicenceNbJour - this.data.LicenceNbJour),
            MontantTVA: 1,
            NomFluxFinancier: `Licence découverte ${this.data.CT_Nom} ${this.data.CT_Prenom} ${this.currentSaisonId}`,
            EST_Reglement: false,
            ID_LicenceDecouverte: this.licence.id,
            EST_Debit: true,
            MKP_FluxFinancierDetail: {
              data: [
                {
                  ID_Structure: this.currentStructureId,
                  ID_Utilisateur: null,
                  ID_Saison: this.currentSaisonId,
                  ID_Tarif: MKP_Tarif.id,
                  MontantHT:
                    MKP_Tarif.MontantUnitaire *
                    (this.licence.LicenceNbJour - this.data.LicenceNbJour),
                  MontantTTC:
                    MKP_Tarif.MontantUnitaire *
                    (this.licence.LicenceNbJour - this.data.LicenceNbJour),
                  MontantTVA: 1,
                  FIN_CodeAnalytique: 0,
                  FIN_CodeCompta: 0,
                  NomFluxFinancierDetail: `Licence découverte ${this.data.CT_Nom} ${this.data.CT_Prenom} ${this.currentSaisonId}`,
                  PrixUnitaire: MKP_Tarif.MontantUnitaire,
                  Quantite:
                    this.licence.LicenceNbJour - this.data.LicenceNbJour,
                  EST_Debit: true,
                },
              ],
            },
          };
          await this.insertLicenceDecouverte({ licence: this.data });
          await this.createFluxFinancierDetail({ flux: fluxStructure });
        }

        if (this.data.LicenceNbJour !== this.licence.LicenceNbJour) {
          // NEW FLUX BACK FFME
          await insertFlux(formatFluxFromUpdateLicenceDecouverte(this.licence.id, this.currentSaisonId, this.currentStructureId, this.licence.LicenceNbJour, this.data.LicenceNbJour));
        }
        success_notification('La licence découverte a bien été modifiée');
        if (this.data.CT_Email) {
          await this.sendEmail();
        }

        this.$emit('onUpdated');
        this.loading = false;
      } catch (error) {
        this.loading = false;
        error_notification('Une erreur est survenue');
        console.log('error:', error);
      }
    },
    async sendEmail() {
      // CHANGER LE CONTEXT PAR UPDATELICENCEDECOUVERTE
      let payloadEmail = {
        Email: this.data?.CT_Email,
        LicenceDecouverteID: this.data?.id.toString(),
        NbDays: this.data.LicenceNbJour.toString(),
        StartDate: this.$moment(this.data.LicenceDateDebut).format(
          'DD/MM/YYYY'
        ),
        ID_Structure: this.currentStructureId.toString(),
        Context: 'createLicenceDecouverte',
        SlugModule: 'gestion_des_licences__saisir_une_licence_decouverte',
      };
      try {
        const response = await send_email(payloadEmail);
        if (!response.ok) {
          error_notification("Une erreur est survenue lors de l'envoi du mail");
        }
      } catch (e) {
        console.log('error on email: ' + e);
      }
    },
  },
};
</script>

<style>
.licence-decouverte-show .adresse_autocomplete input {
  max-width: none !important;
}
</style>
